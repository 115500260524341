import { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import {Context} from "../index";

const useUTMPromocode = () => {
  const [searchParams] = useSearchParams();
  const {basket} = useContext(Context)

  const utmPromocode = searchParams.get("utm_discount");

  if (utmPromocode) {
    basket.setPromocode(utmPromocode);
  }

  return null;
};

export default useUTMPromocode;
