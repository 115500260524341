import appConfig from "../config/appConfig";

class MindBox {

  enabled = appConfig.ANALYTICS.mindBox?.enabled;

  pushPersonalizationEvent(operation, data) {
    if (this.enabled) {
      window.mindbox(operation, {
        data,
      });
    } else {
      console.log(
        '%c%s',
        'color: green; font: 1.2rem/1 Tahoma;',
        'MindBox',
        operation,
        data,
      );
    }
  }

  personalization = {
    viewProduct: ({ id }) => {
      this.pushPersonalizationEvent('personalization.viewProduct', {
        viewProduct: {
          product: {
            ids: {
              website: id.toString(),
            }
          }
        }
      });
    },
    viewCategory: ({ id }) => {
      this.pushPersonalizationEvent('personalization.viewCategory', {
        viewProductCategory: {
          productCategory: {
            ids: {
              website: id.toString(),
            }
          }
        }
      });
    },
  }
}

const mindBox = new MindBox();

export default mindBox;