import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
  authRoutes,
  publicRoutes,
  publicRoutesCatalog,
  publicRoutesProduct,
  publicRoutesUser,
  // routesLookBook
} from "../routes";

import { Route, Routes, Navigate } from "react-router-dom";
import { Context } from "../index";
import LoadingAdmin from "../pages/administrator/LoadingAdmin";
import { FASHIONS_DETAIL } from "../utils/consts";
import Fashion from "../pages/content/fashion/Fashion";
import { useLocation } from "react-router-dom";
import useUTMPromocode from "../hooks/useUTMPromocode";

const AppRouter = observer(() => {
  const { user } = useContext(Context);
  const location = useLocation();
  let Component = publicRoutesCatalog[0].Component;
  let UserComponent = publicRoutesUser[0].Component;
  let ProductComponent = publicRoutesProduct[0].Component;

  let state = location.state;
  useUTMPromocode();
  

  if (/\/fashions\/./g.test(location.pathname) && !state) {
    state = {
      backgroundLocation: "/fashions/"
    }
  }

  return (
    <>
      <Routes location={state?.backgroundLocation || location}>
        {user.isAuth &&
          user.user.role === "ADMIN" &&
          authRoutes.map(({ path, Component }) => (
            <Route key={path} path={path} element={<Component />} />
          ))}

        {publicRoutesUser[0].path.map((path, index) => (
          <Route key={path} path={path} element={<UserComponent />} />
        ))}

        {publicRoutesCatalog[0].path.map((path, index) => (
          <Route key={path} path={path} element={<Component />} />
        ))}

        {publicRoutesProduct[0].path.map((path) => (
          <Route key={path} path={path} element={<ProductComponent />} />
        ))}

        {publicRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}

        <Route exact path={"/admindashboard/*"} element={<LoadingAdmin />} />

        {/*<Route path="*" element={<Navigate to="/content/404/" />} />*/}
      </Routes>

	  {!!state?.backgroundLocation && (
		<Routes>
			<Route path={FASHIONS_DETAIL} element={<Fashion />} />
		</Routes>
	  )}
    </>
  );
});

export default AppRouter;
