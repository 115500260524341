import { makeAutoObservable, action } from "mobx";
import {
    addToBasket,
    checkBasketItems,
    deleteAllBasketItems,
    deleteBaseBasketOneItem, forceDeleteAllBasketItems,
    updateCountItemsBasket
} from "../http/basketApi";
import { getCategories } from "../http/catalogApi";
import { createNewOrder } from "../http/orderApi";

export default class BasketStore {

    totalPrice = 0;
    priceNoSell = 0;
    delPrice = 0;
    promoNitificator = {
        color: 'red',
        message: ''
    };
    promocodeItem = null;
    promoDiscountSumm = 0;
    isLoyaltyActive = false;
    bonusesOrderEarned = 0;
    bonusesOrderAvail = 0;
    bonusesTotal = 0;
    bonusesConfirmed = 0;
    bonusesSpendAll = false;
    usedPromotions = [];
    update = true

    constructor(diliveryStore, userStore) {
        this.delivery = diliveryStore;
        this.user = userStore;
        this.init()
        makeAutoObservable(this)
    }

    init() {
        // Счетчик для шапки, чтобы не вызывать каждый раз метод пересчета, иначе цифра будет мигать.
        this._countItems = 0;
        // Всегда актуальные товары в лайв режиме
        this._basketItems = [];
        this._basketPriceNoSell = 0;
        this._basketPriceCalculated = 0;
        this._totalCountBasketItems = 0;
        this._activeNotification = false;
        this._notificationContent = {};
        this._bonusesValue = '';
        this._bonusesSpend = 0;
        this._bonusesInfo = {
            color: 'red',
            message: ''
        }
        this.calculatedItems = [];
        //Используется для того, чтобы во время регистрации / авторизации
        //Пользователя не дропнуло в личный кабинет во время оформления заказа
        this._registrationOnFlyBasket = false;
        /// order
        this._startBasketNum = 1;
        this._information = [
            {num: 1, name: "Товары, которые вы добавили в корзину", br_name: "корзина", vis_name: "Корзина", button_text:"Оформить заказ"},
            {num: 2, name: "Оформление заказа", br_name: "Оформление заказа", vis_name: "Доставка",button_text:"Далее"},
            {num: 3, name: "Оформление заказа", br_name: "Оформление заказа", vis_name: "Получатель",button_text:"Далее"},
            {num: 4, name: "Оформление заказа", br_name: "Оформление заказа", vis_name: "Оплата",button_text:"Оформить заказ"},
        ]
        // Функциональное окно выбора горордов / выбора дотставки сдек 2
        this._functionalModalState = 0;
        // Если сумма заказа больше 80 000 то отключаем кнопку оформить заказ.
        this._basketOverPrice = false;
        //ИСПОЛЬЗУЕТСЯ для установки ответа интеграции сдека по стоимости доставки из города в город
        //ИСПОЛЬЗУЕТСЯ ПРИ РАСЧЕТАХ
        this._deliveryPrice = 0;
        this._hasGift = false;
        // Копия значения у "переменной" поиска для страницы адреса доставки
        this._orderSearchQuery = null;
        this._activeBasketItems = [];
        this._disabledBasketItems = [];
        this._selectPaymentType = null;
        this._selectPaymentTypeError = "";
        this._countryAndMask = {};
        this._promocode = null;
        this._findUserByNumber = "";
        this._avoidCall = false;
        this._totoalBonusesAvailable = 0;
        this._bonusesAvailableForBasket = 0;
        this._mindboxOrderAmount = 0;
        this.usedPromotions = [];
        this.bonusesOrderEarned = 0;
        this.bonusesOrderAvail = 0;
        this.bonusesTotal = 0;
        this.bonusesSpendAll = false;
        this._bonusesInfo = {
            color: 'red',
            message: ''
        }
        this._noProductsForBonuses = false
        this._confirmedUserPhone = false
        this._favoriteBasketProduct = []
    }


    setTotalCountBasketItems(number) {
        this._totalCountBasketItems = number;
    }

    setUserPhoneConfirm(status){
        this._confirmedUserPhone = status;
    }
    setFindUserByNumber(string) {
        this._findUserByNumber = string;
    }

    setPromocode(object) {
        this.update = true
        this._promocode = object
    }

    setSelectPaymentType(object) {
        this._selectPaymentType = object;
    }

    setSelectPaymentTypeError(string) {
        this._selectPaymentTypeError = string;
    }

    setSavoriteItems(items) {
        this._favoriteBasketProduct = items
    }

    setDeliveryPrice(number) {
        this._deliveryPrice = number;
    }
    setAvoidCall(boolean) {
        this._avoidCall = boolean;
    }

    setOrderSearchQuery(object) {
        this._orderSearchQuery = object;
    }

    setCountryAndMask(object) {
        this._countryAndMask = object;
    }

    setActiveBasketItems(array) {
        this.update = true
        this._activeBasketItems = array;
    }

    setDisabledBasketItems(array) {
        this._disabledBasketItems = array;
    }

    setCountItems(number){
        this._countItems = number
    }

    setItemsPrices(array){
        this.update = false
        if(array){
            this.setBasket([...array])
        }
    }

    setBasketItems(array) {

        let arrayBasketForFloctory = [];
        if(array.length) {
            array.forEach(itemForeach => {
                arrayBasketForFloctory.push(
                    {
                        id:itemForeach.item.id,
                        count: itemForeach.count,
                        price: itemForeach.item.sell_price ? itemForeach.item.sell_price : itemForeach.item.parrent_price, // product price
                    }
                )
            })
        }
        this._basketItems = array
    }

    setActiveNotification(bool) {
        this._activeNotification = bool
    }

    setNotificationContent(object) {
        this._notificationContent = object
    }

    setRegistrationOnFlyBasket(bool) {
        this._registrationOnFlyBasket = bool
    }

    setStartBasketNum(number) {
        this._startBasketNum = number;
    }

    setBasketOverPrice(boolean) {
        this._basketOverPrice = boolean
    }

    setBonusesValue(value) {
        this._bonusesValue = value;
    }

    setBonusesSpend(value) {
        this.update = true
        this._bonusesSpend = value;
    }

    setBonusesInfo(object) {
        this._bonusesInfo = object;
    }

    //1 - выводит модалку с выбором города
    //2 - модалку сдека с ПВЗ и картой
    setFunctionalModalState(number) {
        this._functionalModalState = number;
    }

    setBasketPriceNoSell(number) {
        this._basketPriceNoSell = number;
    }

    setBasketPriceCalculated(number) {
        this._basketPriceCalculated = number;
    }

    clearPromoNotificator() {
        this.promoNitificator.message = '';
    }

    clearBonusesInfo() {
        this.bonusesInfo.message = '';
    }

    setCalculatedItems(array) {
        this.calculatedItems = array;
    }

    setNoProductsForBonuses(boolean) {
        this._noProductsForBonuses = boolean;
    }

    selectAll(state) {
        this.update = true
        this._basketItems = this.basketItems.map(item => {
            item.selected = state
            return item
        })
        this.updateSelected()
    }

    updateSelected() {
        this._activeBasketItems = this.basketItems.filter(item => item.selected)
    }

    get totalCountBasketItems() {
        return this.basketItems.reduce((accum, item) =>  accum + item.count, 0);
    }

    get promocode() {
        return this._promocode;
    }

    get allPrice(){
        let price = 0;
        this._basketItems.forEach(item => {
            price += (item.item.sell_price > 0 ? item.item.sell_price : item.item.product.price) * item.count
        })
        return price;
    }

    get basketOverPrice() {
        return this._basketOverPrice;
    }

    get favoriteItems() {
        return this._favoriteBasketProduct
    }

    get selectPaymentType() {
        return this._selectPaymentType;
    }

    get activeBasketItems() {
        const result = this.divideBasket(this.basketItems).active;
        return result;
    }

    get bonusesValue() {
        return this._bonusesValue;
    }

    get selectedItems(){
        return this._activeBasketItems
    }

    get unSelectedItems(){
        return this.basketItems.filter(item => !item.selected)
    }


    get bonusesSpend() {
        return this._bonusesSpend;
    }

    get bonusesInfo() {
        return this._bonusesInfo;
    }

    get disabledBasketItems() {
        return this.divideBasket(this.basketItems).disabled
    }

    get orderSearchQuery() {
        return this._orderSearchQuery;
    }

    get countryAndMask() {
        return this._countryAndMask;
    }

    get selectPaymentTypeError() {
        return this._selectPaymentTypeError;
    }

    get hasGift() {
        return this.basketItems.some(item => item.item.code.includes('gift'))
    }

    get hasGiftOnly() {
        return this.basketItems.every(item => item.item.code.includes('gift'))
    }

    get basketPriceNoSell() {
        return this._basketPriceNoSell;
    }

    get basketPriceCalculated() {
        return this._basketPriceCalculated;
    }

    get deliveryPrice() {
        return this._deliveryPrice;
    }

    get countItems() {
        return this.activeBasketItems.length
    }

    get basketItems() {
        return this._basketItems
    }

    get activeNotification() {
        return this._activeNotification
    }

    get notificationContent() {
        return this._notificationContent
    }

    get registrationOnFlyBasket() {
        return this._registrationOnFlyBasket;
    }

    get startBasketNum() {
        return this._startBasketNum;
    }

    get information() {
        return this._information;
    }

    get functionalModalState() {
        return this._functionalModalState;
    }

    get findUserByNumber() {
        return this._findUserByNumber;
    }

    get avoidCall() {
        return this._avoidCall;
    }

    get noProductsForBonuses() {
        return this._noProductsForBonuses;
    }

    addProduct = async (item, currentSize) => {
        if(this.user.isAuth) {
            const response = await addToBasket(item, this.user.user.id, currentSize);
            if (!response.error && response.items) {
                this.setBasket(response.items);
                this.basketItems.forEach((itemSearch) => {
                    if(itemSearch.child_product_id === item.id && itemSearch.currentSize === currentSize && itemSearch.colorId === item.colorId) {
                        this.setActiveNotification(true);
                        this.setNotificationContent(
                            {
                                item:itemSearch.item,
                                currentSize:itemSearch.currentSize,
                                count:itemSearch.count,
                                colorId:itemSearch.colorId,
                                id:itemSearch.id
                            }
                        )
                    }
                })
            }
        } else {
            let basketItems = [...this.basketItems];
            if(!basketItems.length){
                basketItems = [
                    {
                        item:item,
                        currentSize:currentSize,
                        count:1,
                        colorId:item.colorId,
                        id:item.id
                    }
                ]
                this.setBasket(basketItems)
                this.setActiveNotification(true);
                this.setNotificationContent({
                    item:item,
                    currentSize:currentSize,
                    count:1,
                    colorId:item.colorId,
                    id:item.id
                })

            } else {
                let isFound = false;
                basketItems = basketItems.map((itemForSearch, index) => {
                    if (itemForSearch.item.id === item.id && itemForSearch.currentSize === currentSize) {
                        isFound = true;
                        this.setNotificationContent({
                            item:itemForSearch.item,
                            count:itemForSearch.count+1,
                            currentSize:itemForSearch.currentSize,
                            colorId:itemForSearch.colorId
                        })

                        return {...itemForSearch, count:itemForSearch.count+1}
                    }
                    return {...itemForSearch};
                })

                if(!isFound){
                    basketItems.push({
                        item:item,
                        currentSize:currentSize,
                        count:1,
                        colorId:item.colorId,
                        id:item.id
                    })
                }

                this.setBasket(basketItems)
                this.setActiveNotification(true);
                if(!isFound) {
                    this.setNotificationContent({
                        item:item,
                        currentSize:currentSize,
                        count:1,
                        colorId:item.colorId,
                        id:item.id
                    })
                }
            }
        }
        const categoriesResponse = await getCategories(item.product.product_category);
        if (categoriesResponse.success && item.child_product_size) {
            const category = item.product.product_category.map((id) => categoriesResponse.data[id]).join('/')
            const findSize = item.child_product_size.find(item => item.size_name === currentSize);
            if (findSize) {
                window.dataLayer.push({
                    event: "ym-ecom",
                    eCategory: "ecommerce",
                    eAction: "add",
                    ecommerce: {
                        currencyCode: "RUB",
                        add: {
                            products: [
                                {
                                    id: String(item.id)+String(item.color.id)+findSize.id,
                                    name : item.product.name,
                                    price: item.sell_price ? item.sell_price : item.parrent_price,
                                    variant: item.color.color_name,
                                    category,
                                    quantity: 1
                                }
                            ]
                        }
                    }
                })
            }
        }
    }

    setBasket = (items) => {
        this.setBasketItems(items)
        localStorage.setItem('basketStorage', JSON.stringify(items))
    }

    removeItem = async (item) => {
        this.update = true
        if (this.user.isAuth) {
            await deleteBaseBasketOneItem(item, this.user.user.id);
            /*if (response) {
                let newBasketItems = []
                response.items.forEach((item) => {
                    let cur = this.basketItems.filter(basketItem => basketItem.item.id === item.item.id)
                    newBasketItems.push({...item, selected: cur.selected})
                })

                //this.setBasket(newBasketItems);
            }*/
        }

        const newBasketItems = this.basketItems.filter(basketItem => {
            const is = basketItem.item.id !== item.item.id || basketItem.currentSize !== item.currentSize || basketItem.colorId !== item.colorId
            return is
        })

        this.setBasket(newBasketItems);

        const categoriesResponse = await getCategories(item.item.product.product_category);
        if (categoriesResponse.success && item.child_product_size) {
            const category = item.product.product_category.map((id) => categoriesResponse.data[id]).join('/')
            const findSize = item.child_product_size.find(item => item.size_name === item.currentSize);
            if (findSize) {
                window.dataLayer.push({
                    event: "ym-ecom",
                    eCategory: "ecommerce",
                    eAction: "remove",
                    ecommerce: {
                        currencyCode: "RUB",
                        add: {
                            products: [
                                {
                                    id: String(item.id)+String(item.color.id)+findSize.id,
                                    name : item.product.name,
                                    price: item.sell_price ? item.sell_price : item.parrent_price,
                                    variant: item.color.color_name,
                                    category,
                                    quantity: 1
                                }
                            ]
                        }
                    }
                })
            }
        }

        this.updateSelected()
    }

    removeAllItems = async (operation, sendDataLayer = true) => {
        this.update = true
        let newBaseBasketItems = [];
        let itemsToDelete = [];
        switch (operation) {
            case 'disable': {
                itemsToDelete = this.disabledBasketItems;
                newBaseBasketItems = this.activeBasketItems;
                break;
            }
            case 'active': {
                itemsToDelete = this.activeBasketItems;
                newBaseBasketItems = this.disabledBasketItems;
                break;
            }
            case 'selected': {
                itemsToDelete = this.selectedItems;
                newBaseBasketItems = this.unSelectedItems;
                break;
            }
            default: {
                itemsToDelete = this.basketItems;
                break;
            }
        }

        if (this.user.isAuth) {
            if (operation === 'all') {
                await forceDeleteAllBasketItems(this.user.user.id);
            } else {
                await deleteAllBasketItems(itemsToDelete, this.user.user.id);
            }
        }
        if(this.basketItems.length && sendDataLayer) {
            const categoryIds = []
            this.basketItems.forEach((item) => {
                const {product} = item.item
                categoryIds.push(...product.product_category)
            })
            const categoriesResponse = await getCategories(categoryIds);
            let products = [];
            for(let oneProduct of this.basketItems) {
                if (categoriesResponse.success && oneProduct.item.child_product_size) {
                    const category = oneProduct.item.product.product_category.map((id) => categoriesResponse.data[id]).join('/')
                    const findSize = oneProduct.item.child_product_size.find(element =>  element.size_name === oneProduct.currentSize);
                    if (category && findSize) {
                        products.push(
                            {
                                id: String(oneProduct.item.id)+String(oneProduct.item.color.id)+findSize.id,
                                name: oneProduct.item.product.name,
                                price: oneProduct.item.sell_price ? oneProduct.item.sell_price : oneProduct.item.parrent_price,
                                category,
                                variant: oneProduct.item.color.color_name,
                                quantity: oneProduct.count,
                            }
                        )
                    }
                }
            }
            if (products.length) {
                window.dataLayer.push({
                    event: "ym-ecom",
                    eCategory: "ecommerce",
                    eAction: "remove",
                    ecommerce: {
                        currencyCode: "RUB",
                        remove: {products}
                    }
                })
            }
        }
        this.setBasket(newBaseBasketItems)
        this.updateSelected()
    }

    chenageCountItem = async (modifier, item) => {
        this.update = true
        if(item.count === 1 && modifier === "decrease") {
            return;
        }
        if (this.user.isAuth) {
            const response = await updateCountItemsBasket(modifier, item, this.user.user.id);
            if (!response.items) {
                return;
            }
            //this.setBasket(response.items);
        }

        const index = this.basketItems.findIndex((basketItem) => {
            return  basketItem.item.id === item.item.id && item.currentSize === basketItem.currentSize && item.colorId === basketItem.colorId
        })
        if (index >= 0) {
            this.basketItems[index].count += modifier === "increase" ? 1 : -1
            this.setBasket([...this.basketItems]);
        }


        const method = modifier === 'increase' ? 'add' : 'remove'
        const categoriesResponse = await getCategories(item.item.product.product_category);
        if (categoriesResponse.success && item.child_product_size) {
            const category = item.product.product_category.map((id) => categoriesResponse.data[id]).join('/')
            const findSize = item.child_product_size.find(item => item.size_name === item.currentSize);
            if (findSize) {
                window.dataLayer.push({
                    event: "ym-ecom",
                    eCategory: "ecommerce",
                    eAction: method,
                    ecommerce: {
                        currencyCode: "RUB",
                        [method]: {
                            products: [
                                {
                                    id: String(item.id)+String(item.color.id)+findSize.id,
                                    name : item.product.name,
                                    price: item.sell_price ? item.sell_price : item.parrent_price,
                                    variant: item.color.color_name,
                                    category,
                                    quantity: 1
                                }
                            ]
                        }
                    }
                })
            }
        }
    }

    upStepPage = async (number) => {
        const checkBasket = await checkBasketItems(this.selectedItems)

        if (!checkBasket.success) {
            window.location.reload();
        }

        if(this.startBasketNum === 1) {

            if (this.basketOverPrice) {
                return false;
            }

            if(this.user.askRegistration && !this.user.isAuth){
                this.setRegistrationOnFlyBasket(true)
                this.user.setStepModals('orderRegistrationOrNot')
                this.user.setOpenAuthModal(1)
                this.user.setShadowOverlay(true)
                return false;
            }
        }

        if(number === 2) {
            let stop = false;

            if(!this.delivery.cityInfo) {

                if(this.user.isAuth && this.user.user.name) {
                    this.delivery.setCityInfoError(this.user.user.name + ", выбериВыберите способ доставки.Выберите способ доставки.те город.");
                } else {
                    this.delivery.setCityInfoError("Выберите город.");
                }

                stop = true;
            }

            if(!this.delivery.active) {

                if(this.user.isAuth && this.user.user.name) {
                    this.delivery.setError(this.user.user.name + ", выберите способ доставки.");
                } else {
                    this.delivery.setError("Выберите способ доставки.");
                }

                stop = true;
            }

            if(this.delivery.active?.id === 16 && !this.delivery.officePvz ) {
                this.delivery.setError("Повторное нажатие на способ доставки откроет карту.");
                stop = true;
            }

            if(stop) {
                return false;
            }
        }

        if(number === 3) {
            let stop = false;

            if(!this.delivery.customerInfo.name) {
                this.delivery.setNameValidShield("Укажите Имя");
                stop = true;
                window.scrollTo({top: document.querySelector('.first_basket_order_user_fields_wrapper').offsetTop, behavior: 'smooth',})
            }

            if(!this.user.user && this.findUserByNumber) {
                stop = true;
            }

            if(!this.delivery.customerInfo.telephone ) {
                this.delivery.setTelephoneValidShield("Укажите номер телефона")
                stop = true;
                window.scrollTo({top: document.querySelector('.first_basket_order_user_fields_wrapper').offsetTop, behavior: 'smooth',})
            }

            if(this.delivery.customerInfo.telephone && this.countryAndMask) {
                let phoneTest = this.delivery.customerInfo.telephone;
                let newphone = phoneTest.replace(/[^+\d]/g, '').replace("+",'');
                if(newphone.length !== Number(this.countryAndMask.number)) {
                    this.delivery.setTelephoneValidShield("Укажите корректный номер")
                    stop = true;
                    window.scrollTo({top: document.querySelector('.first_basket_order_user_fields_wrapper').offsetTop, behavior: 'smooth',})
                }

            }

            if(this.delivery.customerInfo.telephone < 8 && !this.countryAndMask) {
                this.delivery.setTelephoneValidShield("Укажите настоящий номер")
                stop = true;
                window.scrollTo({top: document.querySelector('.first_basket_order_user_fields_wrapper').offsetTop, behavior: 'smooth',})
            }

            let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if(!emailPattern.test(this.delivery.customerInfo.email) || !this.delivery.customerInfo.email){
                this.delivery.setEmailValidShield("Укажите настоящий Email")
                stop = true;
                window.scrollTo({top: document.querySelector('.first_basket_order_user_fields_wrapper').offsetTop, behavior: 'smooth',})
            } else {
                this.delivery.setEmailValidShield("")
            }

            //Валидация адреса только если не ПВЗ-ПВЗ
            if(!this.delivery.officePvz && this.delivery.active.show_room === false) {
                if(!this.orderSearchQuery.value || this.delivery.addressValidShield) {
                    stop = true;
                    if (!this.delivery.addressValidShield) {
                        this.delivery.setAddressValidShield("Пожалуйста, укажите корректный адрес")
                    }
                    window.scrollTo({top: document.querySelector('.first_basket_order_user_fields_wrapper').offsetTop, behavior: 'smooth',})
                }
            }
            if(!this._confirmedUserPhone && !this.user.isAuth && this._countryAndMask){
                stop = true;
                this.delivery.setTelephoneValidShield("Пожалуйста подтвердите номер");
                window.scrollTo({top: document.querySelector('.first_basket_order_user_fields_wrapper').offsetTop, behavior: 'smooth',})
            }

            if(stop) {
                return;
            }
        }

        if(number === 4) {
            if(!this.selectPaymentType) {
                this.setSelectPaymentTypeError("Выберите способ оплаты");
                return;
            }

            if (this.hasGift && this.selectPaymentType.code === 'dolyami') {
                this.setSelectPaymentTypeError("Недоступна покупка сертификатов при оплате Долями");
                return;
            }

            if (this.totalPrice < 3000 && (this.selectPaymentType.code === 'dolyami' || this.selectPaymentType.code === 'ysplit')) {
                let errorText = "Минимальная сумма заказа 3 000р. при оплате";
                errorText += this.selectPaymentType.code === 'dolyami' ? " Долями" : " Яндекс.Сплит";
                this.setSelectPaymentTypeError(errorText);
                return;
            }
            let orderData = {
                user_data: this.user.user,
                BasketItems: this.calculatedItems.filter(item => item.selected),
                cityInfo: this.delivery.cityInfo,
                delivery_client: this.delivery.active,
                deliveryPrice: this.delPrice,
                orderAdressSearch: this.orderSearchQuery,
                deliveryOrderFields: this.delivery.customerInfo,
                pvz_office: this.delivery.officePvz,
                payment_type_json: this.selectPaymentType,
                promocode: this.promocode,
                activeSubs: this.user.activeSubs,
                avoidCall: this.avoidCall,
                spendBonuses: this.bonusesSpend,
                orderTotal: this.totalPrice,
                orderNoSale: this.priceNoSell,
                orderDelPrice: this.delPrice,
                promoDiscountSumm: this.promoDiscountSumm
            }

            const response = await createNewOrder(orderData);

            if (response?.status === 'success') {
                await this.removeAllItems('all', false);
            }

            this.delivery.clear();
            this.init();
            return response;

        }

        this.setStartBasketNum(number + 1)
    }

    downStepPage = (number) => {
        if (!number || number <= 1) {
            return;
        }

        this.setStartBasketNum(number - 1);
    }

    divideBasket(items) {
        let active = [];
        let disabled = [];
        if (!items || !items.length) {
            return {active: active, disabled: disabled}
        }
        items.forEach(item => {
            let sizeName = item.currentSize;
            let currentSize = item.item.child_product_size_field.find(element => element.size_name === sizeName);
            if ((currentSize && (!currentSize.available || !currentSize.isActive)) || !item.item.is_active) {
                disabled.push(item)
            } else {
                active.push(item)
            }
        })
        return {active: active, disabled: disabled}
    }
}