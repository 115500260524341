import React, {
    useContext,
    useEffect,
    useState,
    useMemo,
    useCallback,
    useRef
} from 'react';
import {observer} from "mobx-react-lite";
import NoodlesRain from '../../components/NoodlesRain/NoodlesRain';
import {Context} from "../../index";
import {
    fetchProducts,
    getDataForBreadcrumbs,
    getCategory,
    mindboxViewCategory,
    fetchFilterContext,
    countFilterdProducts
} from "../../http/catalogApi";
import './catalogstyle.css';
import BreadcrmbsAndFilterLine from "../../components/catalog/BrcrmbAndFilter/BreadcrmbsAndFilterLine";
import {Link, useParams} from "react-router-dom";
import OneProductCart from "./OneProductCart";
import Spiner from "../administrator/spiner/Spiner";
import Pagination from "../../components/Paginstion";
import BreadcrumbsCatalog from "../../components/catalog/BrcrmbAndFilter/breadcrumbsCatalog/BreadcrumbsCatalog";
import {pushGoogleAnalyticProductAction} from "../../utils/googleAnalytic";
import {useLocation, useNavigate} from "react-router";
import NotFound from '../content/notFound/NotFound';
import { FilterContext } from "../../services/FilterContext";
import { getParentCategories } from '../../http/catalogApi';
import { getAlterTitleAndDesc, getAlterTitleAndDescHitCategori } from '../../utils/seo.helper';
import mindBox from '../../analytics/mindBox';

const parrentTitles = {
    sale: 'Sale',
    UNISEX: 'UNISEX',
    velvetin: 'YW X VELVETIN',
    'new-collection': 'Новинки',
    coming_soon: 'Скоро в наличии',
    bestseller: 'Бестселлеры',
}

const Catalog = observer(() => {

    const {settings} = useContext(Context);
    const [filters, setFilters] = useState({
        colors: [],
        sizes: [],
        trends: [],
        price_current: []
    })
    const googleAnalyticProducts = {}
    const [notFound, setNotFound] = useState(false);
    const shippedToAnalyticProductIds = []
    const [applyFilter, setApplyFilter] = useState(0);
    const [disabledFilterBtn, setDisabledFilterBtn] = useState(false);
    const [appliedFilters, setaAppliedFilters] = useState({
        colors: [],
        sizes: [],
        trends: [],
        price_current: []
    });
    const [actualCat, setActualCat] = useState({});
    const [catalogFakeCategory, setCatalogFakeCategory] = useState([]);
    const [count, setCount] = useState(0)
    const [countFiltered, setCountFiltered] = useState(0)
    const [productsArray, setProductsArray] = useState([]);
    const [spiner, setSpiner] = useState(true);
    const [category, setCategory] = useState(false)
    const [thirdCategory, setThirdCategory] = useState(false)
    const [fourthCategory, setFourthCategory] = useState(false)
    const [parrentCategory, setParrentCategory] = useState(null)
    const [deviceUUID, setDeviceUUID] = useState('')
    const [userAgent, setUserAgent] = useState('')
    const [showFilters, setShowFilters] = useState(true)
    const [pathName, setPathName] = useState('');
    const [paramsForBreadcrumbs, setParamsForBreadcrumbs] = useState();
    const [catalogModelView, setCatalogModelView] = useState('standart');
    const [transitionButton, setTransitionButton] = useState(false)
    const [seoText, setSeoText] = useState(false)

    const params = useParams()
    const navigate = useNavigate();

    const location = useLocation();

    const limit = 32;

    let totalProductsCount = 0;
    let analyticProductsList = []

    const fetchCatalogFakeCategory = () => {
		getParentCategories().then((data) => {
            if(data.success){
                setCatalogFakeCategory(data.data);
            }
		});
	};

	useEffect(() => {
		fetchCatalogFakeCategory();
	}, []);

    const pushGoogleAnalyticViewItemList = async () => {
        if(!analyticProductsList.length)
            return false
        pushGoogleAnalyticProductAction('view_item_list', analyticProductsList)
        analyticProductsList = []
    }

    const productsObserver = new IntersectionObserver((recordsList ) => {
        recordsList.forEach((record) => {
            if(record.isIntersecting) {
                const {id} = record.target.dataset
                if(!id || shippedToAnalyticProductIds.includes(id))
                    return
                analyticProductsList.push(googleAnalyticProducts[id])
                shippedToAnalyticProductIds.push(id)

                if(analyticProductsList.length >= 15 || shippedToAnalyticProductIds.length >= totalProductsCount)
                    pushGoogleAnalyticViewItemList()
            }
        })
    })

    useEffect(() => {
        if(location) {
            let tmp = location.search
            setPathName(tmp) ;
        }
    }, [location])

    const queryParams = new URLSearchParams(location.search)
    let page = +queryParams.get('page')
    let sort = queryParams.get('sort')

    if (!sort) {
        sort = 'default'
    }

    if (page === 0) {
        page = 1
    }

    if (location.search) {
        const params = new URLSearchParams(location.search);
        page = params.get("page");
        if (!page || page <= 0) {
            page = 1
        }
    } else if (!page || page <= 0) {
        page = 1
    }
    let pageSuffix = '';
    if (page && pathName && page > 1) pageSuffix = ' - страница №' + page;

    useEffect(() => {
        setDeviceUUID(document.cookie.replace(/(?:(?:^|.*;\s*)mindboxDeviceUUID\s*\=\s*([^;]*).*$)|^.*$/, "$1"))
        setUserAgent(window.navigator.userAgent)
    }, [])

    useEffect(() => {
        if (actualCat.id) {
            mindBox.personalization.viewCategory({id: actualCat.id});
            mindboxViewCategory(deviceUUID, userAgent, actualCat)
        }
    }, [actualCat])

    const parrent = params.parrentCategory
    const child = params.childCategory
    const third = params.thirdCategory
    const fourth = params.fourthCategory

    if(parrent == 'sale' && sort == 'default'){
        sort= 'price_up'
    }

    useEffect(() => {
        setSeoText(false)
        if (
            (parrent === 'sale'
            || parrent === 'bestseller'
            || parrent === 'coming_soon'
            || parrent === 'new-collection')
            && category
        ) {
            setSeoText('')
            return
        }
        if(page !== 1){
            setSeoText('')
        } else if (fourthCategory){
            if (fourthCategory.seo_text) {
                setSeoText(fourthCategory.seo_text);
            }
        } else if(thirdCategory){
            if(thirdCategory.seo_text){
                setSeoText(thirdCategory.seo_text)
            }
        } else if(category){
            if(category.seo_text){
                setSeoText(category.seo_text)
            }
        } else if(parrentCategory){
            if(parrentCategory.seo_text){
                setSeoText(parrentCategory.seo_text)
            }
        }
    }, [category, parrentCategory, thirdCategory, fourthCategory, parrent])

    const googleAnalyticCategory = useMemo(() => {
        if(!category && !parrent)
            return {
                item_list_id: 0,
                item_list_name: 'Каталог',
            }

        if(!category && parrent)
            return {
                item_list_id: parrent,
                item_list_name: parrentTitles[parrent],
            }

        return {
            item_list_id: `${parrent}-${category.id}`,
            item_list_name: category.name,
        }
    }, [category, parrent])

    const observeProduct = useCallback((product, ref, selectSize) => {
        totalProductsCount++
        const customProps = {
            index: totalProductsCount,
            ...googleAnalyticCategory,
        }
        if (product.child_product_size) {
            let findSize = product.child_product_size.find(element => element.size_name === selectSize);
            if (findSize) {
                let offerId = String(product.id)+String(product.color.id)+findSize.id;
                googleAnalyticProducts[offerId] = {...product, currentSize: selectSize, customProps}
                productsObserver.observe(ref)
            }
        }
    }, [googleAnalyticCategory])

    useEffect(() => {
        pushGoogleAnalyticViewItemList()
        return pushGoogleAnalyticViewItemList
    }, [category, parrent])

    const setFilterParam = (value) => {
        navigate({pathname: '.', search: `?${newQueryString('sort', value)}`});
        sort = value
    }

    const newQueryString = (key, value) => {
        let searchParams = new URLSearchParams(location.search);
        if (key === 'page') {
            if (value < 2) {
                searchParams.delete(key);
            } else {
                searchParams.set(key, value);
            }
        } else {
            if (value == 'default') {
                searchParams.delete(key)
            } else {
                searchParams.set(key, value);
            }
        }
        return searchParams.toString()
    };

    const handleScroll = () => {
        if(window.scrollY)
            settings.setScrollStatusCatalog(window.scrollY);
    };



    const allPages = Math.ceil(count / limit)

    useEffect(() => {
        if (allPages > 1) {
            setTransitionButton(true)
        } else {
            setTransitionButton(false)
        }

    }, [count])


    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevCats = usePrevious({parrent, child})

    const linkNewPage = (count, limit, page) => {
        if (!count || !limit) {
            setTransitionButton (false)
        }

        if(!page || page <= 0) {
            setTransitionButton (true)
        }

        if(count <= limit) {
            setTransitionButton (false)
        }
    }

    useEffect(() => {
        fetchFilterContext(parrent, child, third, fourth, catalogFakeCategory).then(response => {
            if (response && response.data) {
                if (response.data.hide) {
                    setShowFilters(false);
                    setFilters({colors: [], sizes: [], trends: [], price_current: []})
                    return
                } else {
                    setShowFilters(true);
                }
                setFilters({...response.data, price_current: [response.data.price_min, response.data.price_max], filter_price: [response.data.price_min, response.data.price_max]})
            }
        })
    }, [parrent, child])

    useEffect(() => {
        let needFilter = true;
        window.scrollTo(0, 0);

        const cat = fourth || third || child;
        if (parrent) {
            getCategory(parrent).then(response => {
                if (response.success) {
                    setParrentCategory(response.data);
                    if (!cat) {
                        setActualCat(response.data);
                    }
                } else {
                    setParrentCategory(null)
                }
            })
        }
        switch (cat) {
            case child: {
                getCategory(child).then(response => {
                    if (response.success) {
                        setCategory(response.data);
                        setActualCat(response.data);
                    } else {
                        setCategory(false)
                    }
                })
                break;
            }
            case third: {
                getCategory(third).then(response => {
                    if (response.success) {
                        setCategory(response.data);
                        setThirdCategory(response.data);
                        setActualCat(response.data);
                    } else {
                        setThirdCategory(false)
                    }
                })
                break;
            }
            case fourth: {
                getCategory(fourth).then(response => {
                    if (response.success) {
                        setCategory(response.data);
                        setFourthCategory(response.data);
                        setActualCat(response.data);
                    } else {
                        setFourthCategory(false)
                    }
                })
                break;
            }
            default: {
                break;
            }
        }

        if (prevCats && (prevCats.parrent !== parrent || prevCats.child !== child)) {
            if (applyFilter) {
                needFilter = false;
                setApplyFilter(0);
            }
        } else if (!applyFilter) {
            needFilter = false;
        }

        fetchProducts(parrent, child, third, fourth, catalogFakeCategory, limit, page, sort, needFilter ? appliedFilters : null, true).then(response => {
            setSpiner(false)
            if (response?.status == 404) {
                setNotFound(true);
                return
            }

            if (!response.data.error) {
                setProductsArray(response.data.rows)
                setCount(response.data.count)
            }

            if (response.data.error) {
                setProductsArray([])
                setCount(0)
            }
        })

        getDataForBreadcrumbs(parrent, child, third, fourth, catalogFakeCategory).then(response => {
            setParamsForBreadcrumbs(response)
        })
    }, [parrent, child, sort, page, applyFilter])

    useEffect(() => {
        setDisabledFilterBtn(true);
        countFilterdProducts(parrent, child, third, fourth, catalogFakeCategory, filters).then(response => {
            if (response && response.status == 404) {
                setCountFiltered(0)
            }
            setCountFiltered(response.data.count)
        }).finally(() => {
            setDisabledFilterBtn(false);
        })
    }, [filters.colors, filters.sizes, filters.trends, filters.price_current])

    const setFilteredProducts = () => {
        setaAppliedFilters({
            colors: filters.colors.filter(item => item.checked),
            sizes: filters.sizes.filter(item => item.checked),
            trends: filters.trends.filter(item => item.checked),
            price_current: filters.price_current
        })
        if (page > 1) {
            setPage(1)
            setApplyFilter(applyFilter+1)
        } else {
            setApplyFilter(applyFilter+1)
        }
    }

    const setPage = (value) => {
        navigate({pathname: '.', search: `?${newQueryString('page', value)}`});
    }

    const resetFilters = () => {
        setApplyFilter(0);
    }

    if (notFound) {
        return (
            <NotFound/>
        )
    }

    return (
        <FilterContext.Provider value={{filters, setFilters, setFilteredProducts, countFiltered, resetFilters, disabledFilterBtn}}>
        <>
            {(
                window.location.pathname.includes("catalog/collab/youwannarollton")
            ) && (
                <NoodlesRain/>
            )}
            <div style={{marginTop: settings.headerHeight}} className={"catalog_section"}>
                {
                    notFound ?
                        <NotFound/>
                    : null
                }
                {
                    parrent !== "bestseller" && parrent !== "new-collection" && parrent !== "coming_soon" && parrent !== "sale"  ?
                        getAlterTitleAndDescHitCategori(parrentCategory, parrent, category, location, pageSuffix)
                    : getAlterTitleAndDesc(parrent, category, location, pageSuffix)
                }
                <div className={"filter_and_catalog"}>
                    {
                        productsArray[0] ?
                            <div className={"breadcrmbs_catalog"}>
                                <BreadcrumbsCatalog
                                    breadcrumbsParams={paramsForBreadcrumbs}
                                />
                            </div>
                            : ''
                    }
                    {
                        actualCat.banner ?
                            <div className={'banner_container'}>
                                <img className={'banner_img'} src={
                                    process.env.REACT_APP_API_URL+"uploads/images/categories/" + actualCat.banner}
                                        alt={(actualCat.name ? actualCat.name : '')}
                                />
                            </div>
                        : ''
                    }
                    {
                        productsArray[0] ?
                            <BreadcrmbsAndFilterLine
                                setCatalogModelView={setCatalogModelView}
                                catalogModelView={catalogModelView}
                                currentStateCatalogModelView={catalogModelView}
                                filterParam={sort}
                                setFilterParam={e => setFilterParam(e)}
                                thirdCategory={thirdCategory}
                                fourthCategory={fourthCategory}
                                category={category}
                                parrentCategory={parrentCategory}
                                parrent={parrent}
                                showFilters={showFilters}
                            />
                        : ''
                    }
                    {
                        spiner ?
                            <div className={"products_wrapper " + catalogModelView}>
                                <Spiner/>
                            </div>
                        :
                            <div className={"products_wrapper " + catalogModelView}>
                                {
                                    productsArray[0] ? productsArray.map((item, index) => {
                                            if (index === productsArray.length-1 && settings.scrollStatusCatalog) {
                                                window.scrollTo(0, settings.scrollStatusCatalog)
                                            }
                                            return (<OneProductCart
                                                observeProduct={observeProduct}
                                                item={item}
                                                index={index}
                                                key={item.id}
                                                parrent={parrent}
                                                googleAnalyticCategory={googleAnalyticCategory}
                                                child={child}
                                                handleScroll={e => handleScroll()}
                                            />)
                                        }
                                    ) 
                                    : <div className={"no_find_product"}>Нет товаров</div>
                                }
                            </div>
                    }
                </div>
                {
                    transitionButton && settings.projectWidth && settings.projectWidth < 1024 ?
                        <Link
                            onClick={e => linkNewPage(count, limit, page)}
                            to={page === allPages ? `?${newQueryString('page', parseInt(page) - 1)}` : `?${newQueryString('page', parseInt(page) + 1)}`} className="give_next_products"><span>{page != allPages ? "Показать еще" : "Вернуться назад" }</span></Link>
                    : ''
                }
                <Pagination page={parseInt(page)} limit={limit} count={count}/>
                {
                    seoText ?
                        <div className="seo_text" dangerouslySetInnerHTML={{__html: seoText}}/>
                            : ""
                }
            </div>
        </>
        </FilterContext.Provider>
    );
});

export default Catalog;
