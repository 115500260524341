import React, {useState} from 'react';
import "./childProductTabs.css";
import {removeChildProduct} from "../../../../../http/catalogApi";
import Popup from "../../allproducts/oneProductLine/deletePopup/deletePopup";

const ChildProductTabs = (props) => {
    const [removeState, setRemove] = useState(false);

    const deleteChildProductHandler = (index) => {
        if(props.item.id) {
            removeChildProduct(props.item.id).then(data => {
                if(data.data.message) {
                    props.delete(index)
                }

            } )
            return false;
        }
        props.delete(index)
    }

        const currentColorNameFilter = props.colors.filter(color => color.id === props.item.colorId);
        const nameColor = currentColorNameFilter[0] ? currentColorNameFilter[0].color_name : null;


    return (
        <>
            {
                removeState && <Popup
                    content={<>
                        <b>Удаление цвета</b>
                        <p style={{fontWeght: "bold"}}>Вы действительно хотите удалить цвет: {nameColor} из товара {props.item.name} ?</p>
                        <button className={"delete_item_button"} onClick={e => deleteChildProductHandler(props.index)}>Удалить</button>
                    </>}
                    handleClose={e => setRemove(false)}
                />
            }
            <div className={props.item.activePage ? "one_item_controll_tab_adm active" : "one_item_controll_tab_adm"  }>
                <div className={"one_item_controll_delete"}
                onClick={e => setRemove(true)}
                > + </div>
                <div
                    onClick={props.onClick}
                    className={nameColor === null  ? "change_container_product_tab red" : "change_container_product_tab"}>{nameColor === null ? "Нет цвета" : nameColor }
                </div>

            </div>
        </>
    );
};

export default ChildProductTabs;

