const { Helmet } = require("react-helmet")

const getAlterTitleAndDesc = (parrent, category, location, pageSuffix) => {
  if (parrent === "bestseller") {
      if (category) {
          return (
              <Helmet>
                  <title>Бестселлеры, {category.name} - купить в Москве в интернет-магазине YOU WANNA {pageSuffix}</title>
                  {
                      category.is_secret ? 
                          <meta name="robots" content="noindex, nofollow"/>
                      : ''
                  }
                  <meta name="description"
                      content={"Бестселлеры, " + category.name + " для женщин в интернет-магазине YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07" + pageSuffix}  />
                  <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1)+location.pathname}/>
              </Helmet>
          )
      } else {
          return (
              <Helmet>
                  <title>Бестселлеры женской одежды - купить в Москве в интернет-магазине YOU WANNA {pageSuffix}</title>
                  <meta name="description"
                      content={"Бестселлеры одежды в интернет-магазине YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07" + pageSuffix}/>
                  <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1)+location.pathname}/>
              </Helmet>
          )
      }
  }
  if (parrent === "coming_soon") {
      if (category) {
          return (
              <Helmet>
                  <title>Скоро в наличии {category.name} в интернет-магазине YOU WANNA {pageSuffix}</title>
                  {
                      category.is_secret ? 
                          <meta name="robots" content="noindex, nofollow"/>
                      : ''
                  }
                  <meta name="description"
                      content={"Скоро в наличии " + category.name + " для женщин в интернет-магазине YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07" + pageSuffix}/>
                  <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1)+location.pathname}/>
              </Helmet>
          )
      } else {
          return (
              <Helmet>
                  <title>Скоро в наличии в интернет-магазине YOU WANNA {pageSuffix}</title>
                  <meta name="description"
                      content={"Скоро в наличии в интернет-магазине YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07" + pageSuffix}/>
                  <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1)+location.pathname}/>
              </Helmet>
          )
      }
  }

  if (parrent === "new-collection") {
      if (category) {
          return (
              <Helmet>
                  <title>Новинки в категории {category.name} - купить в Москве в интернет-магазине YOU WANNA {pageSuffix}</title>
                  {
                      category.is_secret ? 
                          <meta name="robots" content="noindex, nofollow"/>
                      : ''
                  }
                  <meta name="description"
                      content={"Новинки в категории " + category.name + " в интернет-магазине YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07" + pageSuffix}/>
                  <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1)+location.pathname}/>
              </Helmet>
          )
      } else {
          return (
              <Helmet>
                  <title>Новинки женской одежды - купить в Москве в интернет-магазине YOU WANNA {pageSuffix}</title>
                  <meta name="description"
                      content={"Новинки одежды в интернет-магазине YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07" + pageSuffix}/>
                  <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1)+location.pathname}/>
              </Helmet>
          )
      }
  }
  if (parrent === "sale") {
      if (category) {
          return (
              <Helmet>
                  <title>Распродажа в категории {category.name} купить по скидке в интернет-магазине YOU WANNA {pageSuffix}</title>
                  {
                      category.is_secret ? 
                          <meta name="robots" content="noindex, nofollow"/>
                      : ''
                  }
                  <meta name="description"
                      content={"Распродажа в категории " + category.name + " в интернет-магазине YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07" + pageSuffix}/>
                  <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1)+location.pathname}/>
              </Helmet>
          )
      } else {
          return (
              <Helmet>
                  <title>Скидки на женскую одежду купить в Москве в интернет-магазине YOU WANNA {pageSuffix}</title>
                  <meta name="description"
                      content={"Распродажа одежды в интернет-магазине YOU WANNA, большие скидки! По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07" + pageSuffix}/>
                  <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1)+location.pathname}/>
              </Helmet>
          )
      }
  }
}

    const getAlterTitleAndDescHitCategori = (parrentCategory, parrent, category, location, pageSuffix) => {
        if(!parrentCategory || !parrent ) {
            return (
                <Helmet>
                    <title>Каталог {pageSuffix}</title>
                    <meta name="description" content={"Каталог интернет-магазина YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07"  +
                    pageSuffix} />
                    <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1)+location.pathname}/>
                </Helmet>
            )
        }
        if(category && parrentCategory || category) {
            if (category.title) {
                return (
                    <Helmet>
                        <title>{category.title} {pageSuffix}</title>
                        {
                            category.description ?
                                <meta name="description"
                                content={category.description + pageSuffix}/>
                            :
                                <meta name="description"
                                    content={"Купить дизайнерские " + category.name.toLowerCase() +
                                        " в интернет-магазине YOU WANNA. ✔ Каталог с оригинальными и стильными товарами. Доставка по Москве и России. Для заказа звоните по телефону: ☎ +7 (926) 887-70-07" +
                                    pageSuffix}/>
                        }
                        {
                            category.is_secret ?
                                <meta name="robots" content="noindex, nofollow"/>
                            : ''
                        }
                        <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1) + location.pathname}/>
                    </Helmet>
                )
            } else {
                return (
                    <Helmet>
                        <title>{category.name} - купить в Москве в интернет-магазине YOU WANNA {pageSuffix}</title>
                        {
                            category.description ?
                                <meta name="description"
                                content={category.description + pageSuffix}/>
                            :
                                <meta name="description"
                                    content={"Купить дизайнерские " + category.name.toLowerCase() +
                                    " в интернет-магазине YOU WANNA. ✔ Каталог с оригинальными и стильными товарами. Доставка по Москве и России. Для заказа звоните по телефону: ☎ +7 (926) 887-70-07" +
                                    pageSuffix}/>
                        }
                        {
                            category.is_secret ?
                                <meta name="robots" content="noindex, nofollow"/>
                            : ''
                        }
                        <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1) + location.pathname}/>
                    </Helmet>
                )
            }
        }

        if (parrentCategory && !category && parrentCategory !== "bestseller" && parrentCategory !== "new-collection" && parrentCategory !== "coming_soon") {
            if (parrentCategory.title) {
                return (
                    <Helmet>
                        <title>{parrentCategory.title} {pageSuffix}</title>
                        {
                            parrentCategory.is_secret ?
                                <meta name="robots" content="noindex, nofollow"/>
                            : ''
                        }
                        <meta name="description"
                            content={"Купить дизайнерский " + parrentCategory.name.toLowerCase() +
                                " в интернет-магазине YOU WANNA. ✔ Каталог с оригинальными и стильными товарами. Доставка по Москве и России. Для заказа звоните по телефону: ☎ +7 (926) 887-70-07"  +
                                pageSuffix}/>
                        <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1)+location.pathname}/>
                    </Helmet>
                )
            } else {
                return (
                    <Helmet>
                        <title>{parrentCategory.name} - купить в Москве в интернет-магазине YOU WANNA {pageSuffix}</title>
                        {
                            parrentCategory.is_secret ?
                                <meta name="robots" content="noindex, nofollow"/>
                            : ''
                        }
                        <meta name="description"
                            content={"Купить дизайнерский " + parrentCategory.name.toLowerCase() +
                                " в интернет-магазине YOU WANNA. ✔ Каталог с оригинальными и стильными товарами. Доставка по Москве и России. Для заказа звоните по телефону: ☎ +7 (926) 887-70-07"  +
                                pageSuffix}/>
                        <link rel='canonical' href={process.env.REACT_APP_API_URL.slice(0, -1)+location.pathname}/>
                    </Helmet>
                )
            }
        }
    }

export {
  getAlterTitleAndDesc,
  getAlterTitleAndDescHitCategori
}