import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './StocksAndTrendsDetail.css';
import { getchildsforslider } from '../../../../http/catalogApi';
import { priceToUserString } from '../../../../http/basketApi';

const StocksAndTrendsDetail = ({ settings, pageData, error }) => {
    console.log('pageData', pageData)
	return (
		<>
			<div className="stocks-trends" style={{ marginTop: settings.headerHeight }}>
				<div className="stocks-trends__container">
					{pageData && (
						<Main
							img={pageData.detail_image}
							content={pageData.main_text}
							title={pageData.name}
							id={pageData.id}
						/>
					)}


                            {
                                pageData.main_slider_active && pageData.slider_images.length ?
                                    <Gallery items={pageData.slider_images} id={pageData.id}/>
                                : ""
                            }

                            {
                                pageData.top_slider_active && pageData.top_slider_products.length ?
                                    <Recommendations items={pageData.top_slider_products}/>
                                : ""
                            }


                            {
                                pageData.bottom_slider_active && pageData.bottom_slider_products.length ?
                                    <Recommendations items={pageData.bottom_slider_products}/>
                                :""
                            }
                        </div>
                    </div>
                </>

    );
};

const Main = ({ img, content, title, id }) => {
	return (
		<>
			<div className="stocks-trends__breadcrumbs">
				<Link className="stocks-trends__breadcrumbs-item" to="/">
					Главная
				</Link>
				<span className="stocks-trends__breadcrumbs-item">{title}</span>
			</div>
			<section className="stocks-trends__section stocks-trends__main">
				<div className="stocks-trends__main-img">
					<img
						src={
							process.env.REACT_APP_API_URL +
							'uploads/images/stockAndTrends/' +
							id +
							'/' +
							img
						}
						alt={title}
						width="680"
						height="816"
					/>
				</div>
				<div className="stocks-trends__main-content">
					<h1 className="stocks-trends__title">{title}</h1>
					<div
						style={{ maxWidth: '600px' }}
						dangerouslySetInnerHTML={{ __html: content }}
					></div>
				</div>
			</section>
		</>
	);
};

const Recommendations = ({ items }) => {
	const [isScrollWrapper, setIsScrollWrapper] = useState(false);
	const scrollWrapper = useRef();
	const [sliderItems, setSliderItems] = useState([]);

	useEffect(() => {
		getchildsforslider(items).then((response) => {
			if (response && !response.error) {
				setSliderItems(response);
			}
		});
		windowResizeHandler();
		window.addEventListener('resize', windowResizeHandler);

		return () => {
			window.removeEventListener('resize', windowResizeHandler);
		};
	}, []);

	function windowResizeHandler() {
		setIsScrollWrapper(scrollWrapper.current?.scrollWidth > scrollWrapper.current?.clientWidth);
	}

	const next = () => {
		const childWidth = scrollWrapper.current.children[0].offsetWidth;
		const offset = window.getComputedStyle(scrollWrapper.current).gap;
		scrollWrapper.current.scrollLeft += childWidth + parseInt(offset);
	};

	const prev = () => {
		const childWidth = scrollWrapper.current.children[0].offsetWidth;
		const offset = window.getComputedStyle(scrollWrapper.current).gap;
		scrollWrapper.current.scrollLeft -= childWidth + parseInt(offset);
	};

	const ucFirst = (str) => {
		if (!str) return str;
		return str[0].toUpperCase() + str.slice(1).toLowerCase();
	};

    return (
        <>
        {
            sliderItems.length ?
                <section className="stocks-trends__section">
                    <div className="stock-trends__header">
                        <h3 className="stock-trends__title">Подборка от наших стилистов</h3>
                        {isScrollWrapper && (
                        <div className="stocks-trends__nav">
                            <button className="stocks-trends__nav-btn" onClick={prev}>
                                <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.83 6L6.41 8.59L5 10L0 5L5 0L6.41 1.41L3.83 4L20 4V6L3.83 6Z" fill="#1A1A1A"/>
                                </svg>
                            </button>
                            <button className="stocks-trends__nav-btn" onClick={next}>
                                <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.17 6L13.59 8.59L15 10L20 5L15 0L13.59 1.41L16.17 4L0 4L0 6L16.17 6Z" fill="#1A1A1A"/>
                                </svg>
                            </button>
                        </div>
                    )}
                    </div>
            
                    <div
                        className="stocks-trends__recs"
                        ref={scrollWrapper}
                    >
                        {sliderItems.map((item, index) => (
                            <Link
                                className="stocks-trends__recs-item"
                                to={item.product.product_url + item.code + '/'}
                                key={index}
                            >
                                <div>
                                    <img
                                        src={process.env.REACT_APP_API_URL+"uploads/images/" + item.product.id + "/" + item.id + "/" + item.img_preview}
                                        alt={item.product.name}
                                        className="stocks-trends__recs-img"
                                    />
                                    <h3 className="stocks-trends__recs-title">{ucFirst(item.product.name)}</h3>
                                    {
                                        !item.price_hidden ?
                                            <div className={"price_and_oldprice_stock_slider"}>
                                                <div className={"sell_price_stock_slider"}>{item.sell_price != 0 ? priceToUserString(item.sell_price)+" ₽" : ''}</div>
                                                <div className={
                                                    item.sell_price != 0 ? "price_stock_slider old_price" : "price_stock_slider"
                                                }>{item.parrent_price ? priceToUserString(item.parrent_price)+" ₽" : ''}</div>
                                            </div> 
                                        : ''
                                    }
                                </div>
                            </Link>
                            ))}
                    </div>
                </section>
            : ""
        }
        </>
    )
}

const Gallery = ({ items, id }) => {
	const [isScrollWrapper, setIsScrollWrapper] = useState(false);
	const scrollWrapper = useRef();
	useEffect(() => {
		windowResizeHandler();
		window.addEventListener('resize', windowResizeHandler);

		return () => {
			window.removeEventListener('resize', windowResizeHandler);
		};
	}, []);

	function windowResizeHandler() {
		setIsScrollWrapper(scrollWrapper.current?.scrollWidth > scrollWrapper.current?.clientWidth);
	}
	const next = () => {
		const childWidth = scrollWrapper.current.children[0].offsetWidth;
		const offset = window.getComputedStyle(scrollWrapper.current).gap;
		scrollWrapper.current.scrollLeft += childWidth + parseInt(offset);
	};

    const prev = () => {
        const childWidth = scrollWrapper.current.children[0].offsetWidth;
        const offset = window.getComputedStyle(scrollWrapper.current).gap;
        scrollWrapper.current.scrollLeft -= childWidth + parseInt(offset);
    }

    return (
        <section className="stocks-trends__section">
            <div className="stock-trends__header">
                <h3 className="stock-trends__title">Inspiration</h3>
                {isScrollWrapper && (
                <div className="stocks-trends__nav">
                    <button className="stocks-trends__nav-btn" onClick={prev}>
                        <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.83 6L6.41 8.59L5 10L0 5L5 0L6.41 1.41L3.83 4L20 4V6L3.83 6Z" fill="#1A1A1A"/>
                        </svg>
                    </button>
                    <button className="stocks-trends__nav-btn" onClick={next}>
                        <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.17 6L13.59 8.59L15 10L20 5L15 0L13.59 1.41L16.17 4L0 4L0 6L16.17 6Z" fill="#1A1A1A"/>
                        </svg>
                    </button>
                </div>
            )}
            </div>

            <div className="stocks-trends__gallery" ref={scrollWrapper}>
                {items.length && items.map((item, index) => (
                    <div
                        className="stocks-trends__gallery-item"
                        key={index}
                    >
                        <img
                            src={process.env.REACT_APP_API_URL+"uploads/images/stockAndTrends/"+id+"/"+item.image}
                            alt=""
                            className="stocks-trends__gallery-img"
                        />
                    </div>
                ))}
            </div>
        </section>
    )
}

export default StocksAndTrendsDetail;
